.slider{
    height: 90vh;
    margin: auto;
    position: relative;
    width: 90%;
    display: grid;
    place-items: center;
    overflow: hidden;
}

.slide-track {
    display: flex;
    width: calc(350px * 10);     
    /* slide track width = total no. of slides (3) x individual slide width (250px) */

    animation: scroll 40s linear infinite;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(calc(-350px * 5))
    }
}

.slide {
    height: 75vh;
    width: auto;
    display: flex;
    align-items: center;
    padding: 15px;
    perspective: 100px;
}

.slide img { 
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: transform 1s;
 }

.slide img:hover {
    transform: translateZ(20px);
 }

 .slider::after, .slider::before {
    background: linear-gradient(to right, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    content: '';
    height: 100%;
    position: absolute;
    width: 10%;
    z-index: 2;
 }

 .slider::before {
    left: 0;
    top: 0;
 }

 .slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
 }

 .slick-dots li {
    width: 40px;
    height: 40px;
    border: 1px solid #922731;
    padding: 4px;
    border-radius: 2px;
 }

 .customarrow {
    height: 100%;
    width: 100%;
 }

 .customarrow img {
    height: 100%;
    width: 100%;
    object-fit: contain;
 }

 .slider-secondiv {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: transform 0.5s linear;
}

 .slider-secondiv:hover {
    transform: scale(1.1);
}

.slider-secondiv img {
    height: 500px !important;
    width: 100% !important;
    object-fit: contain;
}


@media screen and (max-width: 768px) {
    .slider-secondiv img {
        height: 100% !important;
        width: 100% !important;
        /* object-fit: cover; */
    }

    .slick-dots li {
        height: 20px;
        width: 20px;
        margin: 3px;
    }

    .slider-secondiv:hover {
        transform: scale(2);
    }
}