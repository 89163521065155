.mainlogo {
    width: 120px;
    height: 120px;
    /* float: right; */
    display: block;
    margin: 10px 10px 0 auto;
}

.circle-menu li {
    list-style: none;
    display: inline;
}

.circle-menu {
    position: relative;
    width: 26em;
    height: 26em;
    border: 3px solid #922731;
    border-radius: 50%;
    margin: 0 auto;
}

.circle-menu a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 4em;
    height: 4em;
    margin: -2em;
    -moz-border-radius: 4em;
    -webkit-border-radius: 4em;
    border-radius: 4em;
    text-align: center;
    text-decoration: none;
    color: #000;
    background: #fff;
    box-sizing: border-box;
    font-family: var(--main-font-family);
    transition: all ease-in-out 5s;
}

.circle-menu a img {
    width: 20px;
}

.circle-menu a span {
    margin-top: 5px;
}


/* 360/6= 60  so for 6 elements rotate the element every 60deg. 10em translate is half the circle-menu width */

.circle-menu .spiritual {
    transform: rotate(60deg) translate(10em) rotate(-60deg);
    -webkit-transform: rotate(60deg) translate(10em) rotate(-60deg);
    -ms-transform: rotate(60deg) translate(10em) rotate(-60deg);
}

.circle-menu .occupational {
    transform: rotate(120deg) translate(10em) rotate(-120deg);
    -webkit-transform: rotate(120deg) translate(10em) rotate(-120deg);
    -ms-transform: rotate(120deg) translate(10em) rotate(-120deg);
}

.circle-menu .emotional {
    transform: rotate(180deg) translate(10em) rotate(-180deg);
    -webkit-transform: rotate(180deg) translate(10em) rotate(-180deg);
    -ms-transform: rotate(180deg) translate(10em) rotate(-180deg);
}

.circle-menu .environmental {
    transform: rotate(240deg) translate(10em) rotate(-240deg);
    -webkit-transform: rotate(240deg) translate(10em) rotate(-240deg);
    -ms-transform: rotate(240deg) translate(10em) rotate(-240deg);
}

.circle-menu .intellectual {
    transform: rotate(300deg) translate(10em) rotate(-300deg);
    -webkit-transform: rotate(300deg) translate(10em) rotate(-300deg);
    -ms-transform: rotate(300deg) translate(10em) rotate(-300deg);
}

.circle-menu .social {
    transform: rotate(360deg) translate(10em) rotate(-360deg);
    -webkit-transform: rotate(360deg) translate(10em) rotate(-360deg);
    -ms-transform: rotate(360deg) translate(10em) rotate(-360deg);
}

@keyframes circle {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes inner-circle {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(-360deg);
    }
}

#rotator {
    width: 400px;
    height: 400px;
    margin: 20px auto;
    line-height: 1;
    border: 3px solid #922731;
    border-radius: 50%;
    position: relative;
}

.rotatoranimation {
    animation: circle 20s linear infinite;
    transform-origin: 50% 50%;
}

.rotatoranimation:hover,
.rotatoranimation:hover>.rotatordivanimation {
    animation-play-state: paused;
}

#rotator>div {
    width: 120px;
    height: 120px;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #fff;
    cursor: pointer;
    transition: transform 0.5s linear;
}

#rotator>div:hover {
    transform: scale(1.2);
}

.rotatordivanimation {
    animation: inner-circle 20s linear infinite;
}

.wholebg {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    height: 350px;
}

.talkbot-container {
    position: fixed;
    right: 50px;
    bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    text-decoration: none;
    color: #000;
}

@-ms-keyframes spin_1 {
    from {
        -ms-transform: rotate(0deg);
    }

    to {
        -ms-transform: rotate(360deg);
    }
}

@-moz-keyframes spin_1 {
    from {
        -moz-transform: rotate(0deg);
    }

    to {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin_1 {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin_1 {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes circle {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes inner-circle {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(-360deg);
    }
}

@media screen and (max-width: 768px) {
    .circle-menu {
        width: 20em;
        height: 20em;
    }

    .wholebg {
        right: 0;
        width: 100%;
        height: auto;
    }

    .circle-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .circle-menu a {
        width: 2.7em;
        height: 2.7em;
        margin: -1.5em;
    }

    .circle-menu a span {
        font-size: 11px;
    }

    .talkbot-container {
        position: absolute;
    }
}

@media screen and (min-width: 768px) and (max-width: 1365px) {
    #rotator {
        width: 500px;
        height: 500px;
    }

    #rotator>div {
        width: 200px;
        height: 200px;
    }

    #rotator>div img {
        height: 55px;
        width: 55px;
        object-fit: contain;
    }

    #rotator>div span {
        font-size: 18px;
    }
}