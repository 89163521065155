.rail-container {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
  
  .rail {
    width: 300%;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    height: 100%;
    perspective: 100px;
  }
  
  .rail li {
    width: 33%;
    height: auto;
    animation: 15s scroll linear infinite;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: transform 0.5s linear;
  }

  .rail li span {
    font-size: 20px;
    font-family: var(--main-font-family);
    font-weight: 600;
  }

  .rail li:hover {
    transform: translateZ(20px);
 }
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  @media screen and (max-width: 768px) {
    .rail li span {
      font-size: 17px;
    }
  }