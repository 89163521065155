.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center
}

.button {
    width: 60px;
    height: 60px;
    background: lightblue;
    border-radius: 50%;
    margin: 6em 0 2em 0
}

.interim {
    color: gray;
    border: #ccc 1px solid;
    padding: 1em;
    margin: 1em;
    width: 300px
}

.final {
    color: black;
    border: #ccc 1px solid;
    padding: 1em;
    margin: 1em;
    width: 300px;
    border-radius: 16px;
}

.mic-checkbox {
    display: none;
}

.mic-checkbox:checked+.mic-button {
    transform: rotateY(180deg);
    /* opacity: 0; */
}

.button-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    perspective: 500px;
    -moz-perspective: 500px;
    -webkit-perspective: 500px;
}

.mic-button {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    height: 100px;
    width: 100px;
    border-radius: 100%;
    transition: transform 0.5s;
    border: 1px solid #171717;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    position: relative;
    padding: 20px;
    cursor: pointer;
}

.button-message,
.mic {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
}

.button-message {
    position: absolute;
    width: 50px;
    color: #ed9254;
    font-family: var(--main-font-family);
    font-weight: bold;
    text-align: center;
    line-height: 20px;
    z-index: 2;
    transform: rotateY(0deg);
    pointer-events: none;
    left: 23px;
    top: 20px;
}

.mic-button-loader {
    position: absolute;
    height: 102px;
    width: 100px;
    background-color: transparent;
    transform: rotateY(180deg);
    top: -31px;
    left: -50px;
}

.mic-checkbox:checked+.mic-button>.mic>.mic-button-loader {
    border-top: 3px solid #AA1111;
    border-radius: 100%;
    animation: borderLoader 1.3s 0.2s ease-in-out infinite;
}

.mic {
    display: none;
}

.mic:after,
.mic:before,
.mic-base {
    position: absolute;
}

.mic:after {
    content: "";
    top: 7px;
    left: -17px;
    height: 26px;
    width: 26px;
    background-color: transparent;
    border: 4px solid #ed9254;
    border-bottom-left-radius: 110px;
    border-bottom-right-radius: 110px;
    border-top: 0;
}

.mic:before {
    content: "";
    top: 33px;
    left: -2px;
    border-bottom: 18px solid #ed9254;
    border-left: 2px solid #ed9254;
    border-right: 2px solid #ed9254;
}

.mic-base {
    top: 46px;
    left: -10px;
    border-bottom: 5px solid #ed9254;
    border-left: 10px solid #ed9254;
    border-right: 10px solid #ed9254;
}

@keyframes borderLoader {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

.anim-container {
    height: 200px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.circles {
    height: 25px;
    width: 20px;
    border-radius: 50px;
}

.lis1 {animation: lis_ani 10s linear 1s infinite;}
.lis2  {animation: lis_ani 10s linear 1.25s infinite;}
.lis3 {animation: lis_ani 10s linear 1.5s infinite;}
.lis4  {animation: lis_ani 10s linear 1.75s infinite;}


.lis1, .lis4 {
    background-color:  #922731;
}

.lis2, .lis3 {
    background-color:  rgba(146, 39, 49, 0.6);
}

@keyframes lis_ani {
    0% {height: 5vh;}
    9% {height: 5vh;}
    10% {height: 25vh;} 12% {height: 10vh;} 14% {height: 15vh;} 16% {height: 10vh;} 18% {height: 20vh;}
    20% {height: 10vh;} 21% {height: 12vh;} 22% {height: 10vh;} 23% {height: 12vh;} 24% {height: 10vh;}
    25% {height: 20vh;}
    
    37% {height: 10vh;} 39% {height: 15vh;} 41% {height: 10vh;} 43% {height: 20vh;}
    45% {height: 10vh;} 46% {height: 12vh;} 47% {height: 10vh;} 48% {height: 12vh;} 49% {height: 10vh;}
    50% {height: 25vh;}
    
    54% {height: 15vh;} 58% {height: 20vh;}
    60% {height: 10vh;} 62% {height: 10vh;} 63% {height: 12vh;} 64% {height: 10vh;}
    65% {height: 20vh;}
    
    85% {height: 10vh;} 86% {height: 12vh;} 87% {height: 10vh;} 88% {height: 12vh;} 89% {height: 10vh;}
    90% {height: 25vh;}

    99% {height: 5vh;}
    100% {height: 5vh;}
}