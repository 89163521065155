.wholebgsecond {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    height: 45%;
}


.social-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
}

.social-container li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.social-container li img {
    height: 180px;
    width: 180px;
}

.social-container li span {
    font-size: 20px;
    font-family: var(--main-font-family);
    font-weight: 400;
    margin-top: 15px;
}